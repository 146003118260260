<template lang="pug">
  v-list-item(@click="select(card.id)")
    Icon.my-2.mr-4(:id="card.id")
    v-list-item-content
      v-list-item-title
        span(v-if="$game.isRarePrimary(card.id)") ★4 
        span(v-else-if="$game.isRareSecondary(card.id)") ★3 
        span {{name(card.characterId)}}
        //- span(v-if="withMasterRanks([2])") 【期間限定】
        //- span(v-if="card.cardRarityType == 'rarity_birthday'") 【バースデー限定】
        //- span(v-if="withMasterRanks([0, 1, 3, 5])") （ライブ衣装付き）
      v-list-item-subtitle
        | {{card.prefix}}
      //- v-list-item-subtitle {{withMasterRanks([0, 1, 3, 5])}} {{withMasterRanks([2])}}
    v-fab-transition
      v-icon(color="primary", v-if="select(card.id, true)") mdi-check
    v-btn.my-auto(icon, :href="`https://sekai.best/card/${id}`", target="_blank", v-if="openable")
      v-icon mdi-open-in-new
</template>

<script>
import Icon from '@/components/Icon';
import KeyValue from '@/components/KeyValue';

export default {
  name: 'Card',

  props: {
    id: { type: Number },
    selectable: { type: Boolean },
    openable: { type: Boolean },
  },

  components: {
    Icon, KeyValue,
  },

  data() {
    return {
      query: this.$game.query,
    };
  },

  computed: {
    card() {
      return this.$db.cards[this.id];
    },
  },

  methods: {
    name(characterID) {
      return (this.$db.gameCharacters[characterID].firstName || '') + ' ' + (this.$db.gameCharacters[characterID].givenName || '');
    },
    select(cardID, checkSelected) {
      let index = this.query.cardIds.indexOf(cardID);
      if (checkSelected) {
        return index != -1;
      }
      if (this.selectable) {
        if (index == -1) {
          this.query.cardIds.push(cardID);
        } else {
          this.query.cardIds.splice(index, 1);
        }
      }
    },
    withMasterRanks(masterRanks) {
      let ranks = this.card.masterLessonAchieveResources.map(x => x.masterRank);
      for (let masterRank of masterRanks) {
        if (ranks.indexOf(masterRank) == -1) {
          return 0;
        }
      }
      return 1;
    },
  }
};
</script>