import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import './registerServiceWorker';

import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

import games from '@/games';
import i18n from './i18n';

Vue.config.productionTip = false;

let assets = function () {
  return 'https://asset3.pjsekai.moe';
};

Object.defineProperty(Vue.prototype, '$game', {
  get: function () {
    let gameID = this.$route.params.gameID;
    return games[gameID];
  }
});

Object.defineProperty(Vue.prototype, '$db', {
  get: function () {
    let gameID = this.$route.params.gameID;
    return games[gameID].database;
  }
});

Object.defineProperty(Vue.prototype, '$server', {
  get: function () {
    let gameID = this.$route.params.gameID;
    return games[gameID].apiHost;
  }
});

Object.defineProperty(Vue.prototype, '$shop', {
  get: function () {
    return 'https://shop-server.amaoto.moe';
  }
});

Vue.prototype.$assets = assets;

const vue = new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app');

window.vue = vue;

// Array.prototype.shuffle = function () {
//   let i = 0;
//   while (++i < this.length) {
//     let j = i + Math.floor(Math.random() * (this.length - i));
//     let x = this[j];
//     this[j] = this[i];
//     this[i] = x;
//   }
//   return this;
// };

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => (res[key] = obj[key], res), {});
