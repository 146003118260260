<template lang="pug">
  div
    p.title.primary--text.px-4.pt-6 Customize your account

    template(v-if="databaseStatus.status!='ok'")
      .text-center.py-8
        v-progress-circular(indeterminate, color="primary")
        .pa-2.caption
          span Loading Database...

    template(v-else)
      //- v-list-item(dense)
      //-   v-list-item-title Search by ID
      //- v-divider

      //- v-list.py-0(dense)
      //-   v-list-item
      //-     v-text-field(dense, hide-details, type="number", v-model="input", prefix="ID: ", @keyup.enter="$router.push(`account/${input}`)")
      //-     v-btn(text, color="primary", :to="`account/${input}`") ok
      //- v-divider
      //- .py-2

      v-text-field.px-4(dense, hide-details, label="Search by ID..", type="number", append-outer-icon="mdi-magnify", v-model="input",
        @click:append-outer="$router.push(`account/${input}`)",
        @keyup.enter="$router.push(`account/${input}`)")

      .py-2

      Selector

      .text-center.py-8(v-if="!ok")
        v-progress-circular(indeterminate, color="primary")
        .pa-2.caption
          span Searching...

      div(v-else)
        v-list-item(dense)
          v-list-item-title Results
        //- v-divider

        //- v-list.py-0(dense)
        template(v-for="account, i in accounts")
          //- v-divider(v-if="i")
          Account(:account="account")
        //- v-divider

        //- .py-2

        .px-4
          v-btn(color="primary", block, @click="load(true)")
            v-icon mdi-refresh
            span Refresh

        .py-2

</template>

<script>
import axios from 'axios';

import Account from './Account';
import Selector from './Selector';

export default {
  name: 'Search',

  components: { Account, Selector },

  props: ['gameID'],

  data() {
    return {
      accounts: [],
      ok: false,
      fullPath: '',
      input: '',

      databaseStatus: this.$game.databaseStatus,
    };
  },

  methods: {
    load(force) {
      let cache = this.$game.cache;
      this.ok = false;
      let fullPath = this.$route.fullPath;
      if (cache.queries[fullPath] && !force) {
        this.accounts = cache.queries[fullPath].accounts;
        this.ok = true;
      } else {
        axios.post(`${this.$server}/search`, this.$game.query).catch(e => {
          this.$root.$emit('networkError', e.response?.status);
        }).then(response => {
          cache.queries[fullPath] = response.data;
          if (fullPath == this.$route.fullPath) {
            this.accounts = response.data.accounts;
            this.accounts.fullPath = fullPath;
            this.fullPath = fullPath;
            this.ok = true;
          }
          // for (let account of this.accounts) {
          //   cache.accounts[account.id] = account;
          // }
        });
      }
    },
  },


  mounted() {
    this.$game.loadDatabase();
    this.load();

    const query = this.$game.query;
    if (this.$route.query.userCardCountByRarePrimary) {
      query.userCardCountByRarePrimary = parseInt(this.$route.query.userCardCountByRarePrimary);
    }
    if (this.$route.query.cardId) {
      if (Array.isArray(this.$route.query.cardId)) {
        query.cardIds = this.$route.query.cardId.map(x => parseInt(x));
      } else {
        query.cardIds = [parseInt(this.$route.query.cardId)];
      }
    }
  },

  watch: {
    $route(to) {
      if (to.name == 'Search' && to.fullPath != this.accounts.fullPath) {
        this.load();
      }
    }
  }
};
</script>
