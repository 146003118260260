<template lang="pug">
  div
    p.title.primary--text.px-4.pt-6 Recover an Order

    Divider
    v-list.py-0(dense)
      template(v-for="game, id, i in games")
        Divider(v-if="i", :inset="72")
        v-list-item(@click="gameID = id")
          v-list-item-avatar
            v-img(:src="game.icon")
          v-list-item-content
            v-list-item-title.text-wrap
              span {{game.fullName}} 
              v-list-item-action-text Server: {{game.region}}
          v-fab-transition
            v-icon(color="primary", v-if="gameID == id") mdi-check
    Divider

    .py-2

    v-text-field.px-4(dense, hide-details, label="Account ID", type="number", v-model="accountID")

    .py-2

    v-text-field.px-4(dense, label="Email", type="email", v-model="email")
    
    .py-2

    v-textarea.px-4(outlined, auto-grow, label="Voucher Codes", 
      v-model="ticketsInput", dense, hint="Enter one Voucher Code each line")

    .px-4
      v-btn(block, color="primary", @click="submit", :disabled="!(gameID && accountID && ticketsInput)") Submit

    .py-2

    .text-center.py-8(v-if="!resultOK")
      v-progress-circular(indeterminate, color="primary")

    div(v-if="resultOK && result.account")
      v-list-item(dense)
        v-list-item-title Account Code (Click to copy)
      Divider
      v-list.py-0(dense)
        v-list-item(@click="$copyText(result.account.inheritID)")
          v-list-item-content: v-list-item-title Transfer ID
          v-list-item-action: v-list-item-action-text {{result.account.inheritID}}
        Divider(inset=16)
        v-list-item(@click="$copyText(result.account.inheritPassword)")
          v-list-item-content: v-list-item-title Password
          v-list-item-action: v-list-item-action-text {{result.account.inheritPassword}}
      Divider
      v-list-item(dense)
        v-list-item-content: v-list-item-action-text Please make sure that you copied and saved the password! Once the site is closed, the password will not be shown again! 
    
    div(v-else-if="resultOK && result.status")
      v-list-item(dense)
        v-list-item-content: v-list-item-action-text We cannot verify your order. Error code: {{result.status}}.

    v-list-item(dense)
      v-list-item-content: v-list-item-action-text Any problem please e-mail shop@pjsekai.moe
    .py-2

</template>

<script>
import Divider from '@/components/Divider';
import games from '@/games';
import currency from '@/currency';

import axios from 'axios';

export default {
  name: 'Recover',

  components: { Divider },

  data() {
    return {
      games, currency,

      gameID: '',
      accountID: '',
      ticketsInput: '',
      email: '',

      resultOK: true,
      result: { status: 0 },
    };
  },

  methods: {
    submit() {
      this.resultOK = false;
      axios.post(`${this.game.apiHost}/recover?id=${this.accountID}&email=${this.email}`, {
        ticketIDs: this.ticketsInput.split('\n'),
      }).catch(e => {
        this.result = { status: e.response?.status };
      }).then(response => {
        this.result = response?.data || { status: this.result.status };
        this.resultOK = true;
      });
    },
  },

  computed: {
    game() {
      return games[this.gameID];
    },
  },

  mounted() {
    if (this.$route.query.game) {
      this.gameID = this.$route.query.game;
    }
    if (this.$route.query.game && this.$route.query.id) {
      this.accountID = this.$route.query.id;
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.resultOK && this.result.account) {
      const answer = window.confirm('Are you sure to leave? \nTransfer ID and Password will be never shown again! ');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>