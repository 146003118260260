<template lang="pug">
  .px-4.pb-4
    v-card(:key="`account-${account.id}`", :to="`account/${account.id}`")
      v-list-item(dense)

        //- v-list-item(dense, :key="`account-${account.id}`", :to="`account/${account.id}`")
        v-list-item-content
          v-list-item-title
            KeyValue.d-inline-block.mr-2(k="★4", :v="account.user.userCardCountsByRarityType.find(userCardCountByRarityType => userCardCountByRarityType.cardRarityType == 'rarity_4').count", style="width: 64px")
            KeyValue.d-inline-block.mr-2(k="★3", :v="account.user.userCardCountsByRarityType.find(userCardCountByRarityType => userCardCountByRarityType.cardRarityType == 'rarity_3').count", style="width: 64px")
            KeyValue.d-inline-block(:v="`💎${account.user.jewel}`", style="width: 64px")

        v-list-item-action: v-list-item-action-text {{currency.currency.sign}}{{account.price * currency.currency.rate}}
        v-icon(small) mdi-chevron-right

      Divider.pb-2(inset=16)

      template(v-if="userCardsSelectedPrimary.length + userCardsSelectedSecondary.length")
        .d-flex.flex-wrap.px-2
          Icon.pl-2.pb-2(v-for="userCard in userCardsSelectedPrimary",
            :key="`${account.id},${userCard.cardId}`", :id="userCard.cardId", size=40)
          Icon.pl-2.pb-2(v-for="userCard in userCardsSelectedSecondary",
            :key="`${account.id},${userCard.cardId}`", :id="userCard.cardId", size=40)

        Divider.pb-2(inset=16)

      .d-flex.flex-wrap.px-2
        Icon.pl-2.pb-2(v-for="userCard in userCardsNotSelectedPrimary",
          :key="`${account.id},${userCard.cardId}`", :id="userCard.cardId", size=32)

        //- template(v-if="account.materials[16]")
        //-   v-badge(bordered, :offset-x="20", :offset-y="20", :content="account.materials[16]")
        //-     Icon.pl-2.pb-2(:src="require(`@/assets/materials/material16.png`)", size=32)

        //- template(v-if="account.materials[15]")
        //-   v-badge(bordered, :offset-x="20", :offset-y="20", :content="account.materials[15]")
        //-     Icon.pl-2.pb-2(:src="require(`@/assets/materials/material15.png`)", size=32)

    //- Scroller.pb-2(:key="`account-detail-${account.id}-3`", icon=32, v-show="userCardsSelectedSecondary.length")
    //-   div.pl-2

    //-   template(v-if="userCardsSelectedSecondary.length")
    //-     Icon.pl-2(v-for="cardID in userCardsSelectedSecondary",
    //-       :key="`${account.id},${cardID}`", :id="cardID", size=32)
    //-     Divider.pl-2(vertical)

    //-   template
    //-     Icon.pl-2(v-for="cardID in userCardsNotSelectedSecondary",
    //-       :key="`${account.id},${cardID}`", :id="cardID", size=32)


    //-   div.px-2

</template>

<script>
import KeyValue from '@/components/KeyValue';
import Icon from '@/components/Icon';
import Divider from '@/components/Divider';
import Scroller from '@/components/Scroller';

import currency from '@/currency';

export default {
  name: 'Account',

  props: {
    account: {},
  },

  components: {
    KeyValue,
    Icon,
    Divider,
    Scroller,
  },

  data() {
    return {
      query: this.$game.query,
      currency: currency,
    };
  },

  computed: {
    userCardsSelectedPrimary() {
      return this.account.user.userCards
        .filter(userCard => this.$game.isRarePrimary(userCard.cardId) && this.query.cardIds.indexOf(userCard.cardId) != -1);
    },
    userCardsSelectedSecondary() {
      return this.account.user.userCards
        .filter(userCard => this.$game.isRareSecondary(userCard.cardId) && this.query.cardIds.indexOf(userCard.cardId) != -1);
    },
    userCardsNotSelectedPrimary() {
      return this.account.user.userCards
        .filter(userCard => this.$game.isRarePrimary(userCard.cardId) && this.query.cardIds.indexOf(userCard.cardId) == -1);
    },
    userCardsNotSelectedSecondary() {
      return this.account.user.userCards
        .filter(userCard => this.$game.isRareSecondary(userCard.cardId) && this.query.cardIds.indexOf(userCard.cardId) == -1);
    },
  },
};
</script>