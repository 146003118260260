<template lang="pug">
  div
    p.title.primary--text.px-4.pt-6 New Order

    v-expand-transition
      div(v-show="step == 0")
        v-list-item(dense)
          v-list-item-title Select Game

        Divider
        v-list.py-0(dense)
          template(v-for="game, id, i in games")
            Divider(v-if="i", :inset="72")
            v-list-item(@click="gameID = id")
              v-list-item-avatar
                v-img(:src="game.icon")
              v-list-item-content
                v-list-item-title.text-wrap
                  span {{game.fullName}} 
                  v-list-item-action-text Server: {{game.region}}
              v-fab-transition
                v-icon(color="primary", v-if="gameID == id") mdi-check
        Divider

        .py-2

        div(v-if="gameID", :key="`order-${gameID}`")
          v-list-item(dense)
            v-list-item-title Search Account

          v-text-field.px-4(dense, hide-details, label="Account ID", type="number", v-model.number="accountID",
            @blur="gameID && accountID && searchAccount()", 
            @keyup.enter="$event.target.blur()", 
            @change="accountError = false", :error="accountError")
          .py-2

        .text-center.py-8(v-if="!accountOK")
          v-progress-circular(indeterminate, color="primary")

        v-list-item(dense, v-if="accountOK && !account.deletedAt === false")
          v-list-item-content
            v-list-item-action-text This account is not available. 
              a(@click="recover=true; step += 1") Have bought this account earlier?

    div(v-if="accountOK && account.id && !account.deletedAt || recover")
      Divider
      v-list.py-0(dense)
        v-list-item(@click)
          v-list-item-content: v-list-item-title ID
          v-list-item-action: v-list-item-action-text {{account.id}}
        Divider(inset=16)
        v-list-item(@click)
          v-list-item-content: v-list-item-title Price
          v-list-item-action: v-list-item-action-text
            span(v-if="!account.deletedAt") {{currency.currency.name}} {{currency.currency.sign}}{{account.price*currency.currency.rate}}
            span(v-else) Not available
        Divider(inset=16)
        v-list-item(:to="`${game.id}/account/${accountID}`", target="_blank")
          v-list-item-content: v-list-item-title Details
          v-list-item-action: v-icon(small) mdi-chevron-right
      Divider

      .py-2

      .px-4
        v-expand-transition
          v-btn(v-show="step == 0", block, color="primary", @click="step += 1") Next
        v-expand-transition
          v-btn(v-show="step != 0 && !(resultOK && result.account)", block, color="primary", outlined, @click="step -= 1") Previous

      .py-2

    v-expand-transition
      div(v-show="step == 1 && !(resultOK && result.account)")
        v-list-item(dense)
          v-list-item-title Vouchers

        template(v-if="!recover")
          Divider
          v-list.py-0(dense)
            v-list-item(href="https://skye1204gaming.com/products/global-jp-instant-4-combo-starter-project-sekai-colorful-stage", target="_blank")
              v-list-item-content: v-list-item-title Get Vouchers at skye1204gaming.com (instant)
              v-list-item-action: v-icon(small) mdi-chevron-right
            Divider(inset=16)
            v-list-item(href="https://item.taobao.com/item.htm?ft=t&id=669274515214", target="_blank")
              v-list-item-content: v-list-item-title Get Vouchers at Taobao (instant)
              v-list-item-action: v-icon(small) mdi-chevron-right
          Divider
          .py-2

        .px-4
          v-textarea(outlined, auto-grow, label="Voucher Codes", 
            v-model="ticketsInput", dense, hint="Enter one Voucher Code each line",
            @change="ticketsError = false", :error="ticketsError")
          v-btn(block, color="primary", @click="checkTickets()", v-if="!recover") Check
          v-btn(block, color="primary", @click="submitRecover()", v-if="recover") Recover

        .py-2

        .text-center.py-8(v-if="!ticketsOK || (recover && !resultOK)")
          v-progress-circular(indeterminate, color="primary")

        div(v-if="tickets.length && !ticketsError && ticketsOK")
          Divider
          v-list.py-0(dense)
            template(v-for="ticket, i in tickets")
              Divider(inset=16, v-if="i")
              v-list-item(@click)
                v-list-item-content: v-list-item-title {{hideTicket(ticket.code)}}
                v-list-item-action: v-list-item-action-text
                  span {{currency.currency.sign}}{{ticket.balance*currency.currency.rate}}
                  template(v-if="ticketsAmount>=account.price")
                    span  → 
                    span.primary--text {{currency.currency.sign}}{{ticket.balanceAfter*currency.currency.rate}}

          Divider
          .py-2

          Divider
          v-list.py-0(dense)
            v-list-item(@click)
              v-list-item-content: v-list-item-title Total
              v-list-item-action: v-list-item-action-text
                span {{currency.currency.sign}}{{ticketsAmount*currency.currency.rate}}
                span.primary--text(v-if="ticketsAmount<account.price")  (not enough for {{currency.currency.sign}}{{account.price*currency.currency.rate}})

          Divider
          .py-2

    v-expand-transition
      div(v-show="step == 1 && accountOK && !account.deletedAt && ticketsOK && ticketsAmount>=account.price && !(resultOK && result.account)")
        .px-4
          v-text-field(dense, label="Email", type="email", v-model="email", hint="Optional. For after-sales only. ")
          v-btn(block, color="primary", @click="submit", :disabled="!(accountOK && !account.deletedAt && ticketsOK && ticketsAmount>=account.price && resultOK && !result.account)") Submit
        .py-2

        .text-center.py-8(v-if="!resultOK")
          v-progress-circular(indeterminate, color="primary")

    v-expand-transition
      div(v-show="resultOK && result.account")
        .text-center.py-8
          v-icon(x-large, color="primary") mdi-check-circle-outline
        v-list-item(dense)
          v-list-item-title Account Code (Click to copy)
        Divider
        v-list.py-0(dense)
          v-list-item(@click="copyText(result.account.user.inheritId); ")
            v-list-item-content: v-list-item-title Transfer ID
            v-list-item-action
              v-list-item-action-text(v-if="result.account")
                span {{result.account.user.inheritId}}
                v-icon.pl-2(small, v-if="copiedText==result.account.user.inheritId") mdi-clipboard-check-outline
          Divider(inset=16)
          v-list-item(@click="copyText(result.account.user.inheritPassword)")
            v-list-item-content: v-list-item-title Password
            v-list-item-action
              v-list-item-action-text(v-if="result.account")
                span {{result.account.user.inheritPassword}}
                v-icon.pl-2(small, v-if="copiedText==result.account.user.inheritPassword") mdi-clipboard-check-outline
        Divider
        v-list-item(dense)
          v-list-item-content: v-list-item-action-text Please make sure that you copied and saved the password! Once the site is closed, the password will not be shown again! 
        v-list-item(dense)
          v-list-item-content: v-list-item-action-text Any problem please e-mail shop@pjsekai.moe
        .py-2

    div(style="height: 64px")
</template>

<script>
import Divider from '@/components/Divider';
import games from '@/games';
import currency from '@/currency';

import axios from 'axios';

export default {
  name: 'Order',

  components: { Divider },

  data() {
    return {
      games, currency,

      step: 0,

      gameID: '',
      accountID: null,
      accountOK: true,
      accountError: false,
      account: {},

      ticketsInput: '',
      ticketsOK: true,
      tickets: [],
      ticketsError: false,
      ticketsAmount: 0,

      email: '',
      resultOK: true,
      result: {},

      recover: false,
      copiedText: '',
    };
  },

  methods: {
    searchAccount() {
      let accountID = this.accountID;
      this.accountOK = false;
      this.accountError = false;
      axios.get(`${this.$shop}/account/${this.accountID}`).catch(() => {
        if (accountID == this.accountID) {
          this.account = {};
          this.accountOK = true;
          this.accountError = true;
        }
      }).then(response => {
        if (accountID == this.accountID) {
          this.account = response?.data || {};
          this.accountOK = true;
          if (this.account.deletedAt || this.account.user.deletedAt) {
            this.accountError = true;
          }
        }
      });
    },
    checkTickets() {
      this.ticketsOK = false;
      let ticketsInput = this.ticketsInput;
      let ticketIDs = this.ticketsInput.split('\n').map(x => x.trim());
      let ticketsOrder = {};
      for (let i in ticketIDs) {
        ticketsOrder[ticketIDs[i]] = i;
      }
      axios.post(`${this.$shop}/voucher/query`, {
        voucherCodes: ticketIDs,
      }).catch(() => {
        if (ticketsInput == this.ticketsInput) {
          this.tickets = [];
          this.ticketsError = true;
          this.ticketsAmount = 0;
          this.ticketsOK = true;
        }
      }).then(response => {
        if (ticketsInput == this.ticketsInput) {
          this.tickets = response?.data?.vouchers || [];
          this.tickets.sort((a, b) => (ticketsOrder[a.code] - ticketsOrder[b.code]));

          let price = this.account.price;
          this.ticketsError = this.tickets.length == 0;
          this.ticketsAmount = 0;
          this.tickets.forEach(ticket => {
            this.ticketsAmount += ticket.balance;
            let amount = price < ticket.balance ? price : ticket.balance;
            ticket.balanceAfter = ticket.balance - amount;
            price -= amount;
          });
          this.ticketsOK = true;
        }
      });
    },
    submit() {
      this.resultOK = false;
      axios.post(`${this.$shop}/order`, {
        accountId: this.accountID,
        email: this.email,
        orderVouchers: this.tickets.map(ticket => ({
          voucherCode: ticket.code,
        })),
      }).catch(e => {
        this.$root.$emit('networkError', e.response?.status);
      }).then(response => {
        this.result = response?.data || {};
        this.resultOK = true;
      });
    },
    submitRecover() {
      this.resultOK = false;
      axios.post(`${this.$shop}/order/query`, {
        accountId: this.accountID,
        email: this.email,
        orderVouchers: this.ticketsInput.split('\n').map(x => x.trim()).map(voucherCode => ({
          voucherCode: voucherCode,
        })),
      }).catch(e => {
        this.$root.$emit('networkError', e.response?.status);
      }).then(response => {
        this.result = response?.data || {};
        this.resultOK = true;
      });
    },
    hideTicket(ticketID) {
      let length = ticketID.length;
      return ticketID[0] + '*'.repeat(length - 2) + ticketID[length - 1];
    },
    copyText(text) {
      this.$copyText(text);
      this.copiedText = text;
    }
  },

  computed: {
    game() {
      return games[this.gameID];
    },
    clipboard() {
      return [
        `Transfer ID: ${this.result?.account?.inheritID}`,
        `Password: ${this.result?.account?.inheritPassword}`,
      ].join('\n');
    }
  },

  mounted() {
    if (this.$route.query.game) {
      this.gameID = this.$route.query.game;
    }
    if (this.$route.query.game && this.$route.query.id) {
      this.accountID = parseInt(this.$route.query.id);
      this.searchAccount();
    }
  },
};
</script>
