<template lang="pug">
  div
    div(:style="style")
      slot
</template>

<script>
export default {
  name: 'Icon',
  props: {
    'id': { type: Number, default: -1 },
    'src': { type: String },
    'size': { default: 40 },
    'after': { type: Boolean },
  },
  computed: {
    style() {
      if (this.id == -1) {
        return {
          'height': `${this.size}px`,
          'width': `${this.size}px`,
          'background-image': `url(${this.src})`,
          'background-size': '100% 100%',
        };
      } else {
        return {
          'height': `${this.size}px`,
          'width': `${this.size}px`,
          'background-image': [
            `url(${require(`../assets/attribute/icon_attribute_${this.card.attr}.png`)})`,
            this.limited ? `url(${require('../assets/limited.png')})` : null,
            !this.limited && this.withCostume ? `url(${require('../assets/costume.png')})` : null,
            `url(${require(`../assets/${this.card.cardRarityType || ('rarity_' + this.card.rarity)}.png`)})`,
            `url(${this.$assets()}/thumbnail/chara/${this.card.assetbundleName}_${this.after ? 'after_training' : 'normal'}.webp)`,
          ].filter(x => x).join(','),
          'background-size': [
            '25% 25%',
            this.limited ? '100% 100%' : null,
            !this.limited && this.withCostume ? '25% 25%' : null,
            '100% 100%',
            '100% 100%',
          ].filter(x => x).join(','),
          'background-position': [
            '0% 0%',
            this.limited ? 'center' : null,
            !this.limited && this.withCostume ? '100% 0%' : null,
            'center',
            'center',
          ].filter(x => x).join(','),
        };
      }
    },
    card() {
      return this.$db.cards[this.id];
    },
    limited() {
      return this.withMasterRanks([2]) || this.card.cardRarityType == 'rarity_birthday';
    },
    withCostume() {
      return false; // this.withMasterRanks([0, 1, 3, 5]);
    }
  },
  methods: {
    withMasterRanks(masterRanks) {
      let ranks = this.card.masterLessonAchieveResources.map(x => x.masterRank);
      for (let masterRank of masterRanks) {
        if (ranks.indexOf(masterRank) == -1) {
          return 0;
        }
      }
      return 1;
    },
  }
};
</script>
