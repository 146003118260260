import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 512,
      sm: 768,
      md: 1024,
      lg: 1280,
    },
    scrollBarWidth: 0,
  },
  theme: {
    themes: {
      light: {
        primary: colors.purple.lighten3,
        secondary: colors.purple.lighten4,
      },
    },
    options: {
      customProperties: true,
    },
    dark: false,
  },
});
