<template lang="pug">
  .text-center.py-8(v-if="databaseStatus.status!='ok'")
    v-progress-circular(indeterminate, color="primary")
    .pa-2.caption
      span Loading Database...

  .text-center.py-8(v-else-if="!ok")
    v-progress-circular(indeterminate, color="primary")
    .pa-2.caption
      span Searching...

  div(v-else)
    .py-2

    v-divider
    v-list.py-0(dense)
      v-list-item(@click)
        v-list-item-content: v-list-item-title ID
        v-list-item-action: v-list-item-action-text {{account.id}}
      Divider(inset=16)
      v-list-item(@click)
        v-list-item-content: v-list-item-title Jewel 💎
        v-list-item-action: v-list-item-action-text {{account.user.jewel}}

    v-divider
    .py-2

    v-list-item(dense)
      v-list-item-content: v-list-item-title ★4
      v-list-item-action: v-list-item-action-text {{account.user.userCardCountsByRarityType.find(userCardCountByRarityType => userCardCountByRarityType.cardRarityType == 'rarity_4').count}}
    v-divider

    v-list.py-0(dense)
      template(v-for="userCard, i in account.user.userCards.filter(userCard => $game.isRarePrimary(userCard.cardId))")
        Divider(inset=72, v-if="i!=0")
        Card(:id="userCard.cardId")

    v-divider
    .py-2

    v-list-item(dense)
      v-list-item-content: v-list-item-title ★3
      v-list-item-action: v-list-item-action-text {{account.user.userCardCountsByRarityType.find(userCardCountByRarityType => userCardCountByRarityType.cardRarityType == 'rarity_3').count}}
    v-divider
    v-list.py-0(dense)
      template(v-for="userCard, i in account.user.userCards.filter(userCard => $game.isRareSecondary(userCard.cardId))")
        Divider(inset=72, v-if="i!=0")
        Card(:id="userCard.cardId")
      v-list-item(v-if="account.user.userCardCountsByRarityType.find(userCardCountByRarityType => userCardCountByRarityType.cardRarityType == 'rarity_3').count == 0")
        v-list-item-content: v-list-item-subtitle None

    //- v-divider
    //- .py-2

    //- v-list-item(dense)
    //-   v-list-item-content: v-list-item-title Tickets
    //- v-divider
    //- v-list.py-0(dense)
    //-   template(v-for="amount, gachaTicketID, i in Object.filter(account.gachaTickets, amount => amount)")
    //-     Divider(inset=72, v-if="i")
    //-     v-list-item(@click)
    //-       Icon.my-2.mr-4(:src="`${$assets()}/thumbnail/gacha_ticket/${$db.gachaTickets[gachaTicketID].assetbundleName}.webp`")
    //-       v-list-item-content
    //-         v-list-item-title {{$db.gachaTickets[gachaTicketID].name}}
    //-       v-list-item-action
    //-         v-list-item-action-text {{amount || 0}}

    v-divider
    .py-2

    v-list-item(dense)
      v-list-item-content: v-list-item-title Materials
    v-divider
    v-list.py-0(dense)
      //- template(v-for="amount, materialID, i in Object.filter(account.materials, amount => amount)")
      template(v-for="userMaterial, i in account.user.userMaterials.filter(userMaterial => userMaterial.quantity)")
        Divider(inset=72, v-if="i")
        v-list-item(@click)
          Icon.my-2.mr-4(:src="`${$assets()}/thumbnail/material/material${userMaterial.materialId}.webp`")
          v-list-item-content
            v-list-item-title {{$db.materials[userMaterial.materialId].name}}
            //- v-list-item-subtitle {{$db.materials[materialID].flavorText}}
          v-list-item-action
            v-list-item-action-text {{userMaterial.quantity || 0}}

    v-divider
    .py-2

      //- v-divider

      //- v-list-item
      //-   v-list-item-content: v-list-item-title Gacha Times
      //-   v-list-item-action: v-list-item-action-text {{account.gachaTimes}}

      //- v-divider.pb-4

    v-divider
    v-list.py-0(dense)
      v-list-item(:to="`/order?game=${$game.id}&id=${id}`")
        v-list-item-content: v-list-item-title Self-service Purchase
        v-list-item-action: v-icon(small) mdi-chevron-right
    v-divider
    .py-2

    v-list-item(style="visibility: hidden")
      v-list-item-content
        v-list-item-title ID: {{id}}
      v-list-item-action
        v-list-item-title(v-if="account.deletedAt || account.user.deletedAt") Sold at {{ new Date(account.deletedAt || account.user.deletedAt).toLocaleString() }}
        v-list-item-title(v-else) {{currency.currency.name}} {{currency.currency.sign}}{{account.price*currency.currency.rate}}

    div(style="position: fixed; bottom: 0px; width: 100%; max-width: 768px")
      Divider
      v-list(dense).pt-0
        v-list-item
          v-list-item-content
            v-list-item-title ID: {{id}}
          v-list-item-action
            v-list-item-title(v-if="account.deletedAt || account.user.deletedAt") Sold at {{ new Date(account.deletedAt || account.user.deletedAt).toLocaleString() }}
            v-list-item-title(v-else) {{currency.currency.name}} {{currency.currency.sign}}{{account.price*currency.currency.rate}}

</template>

<script>
import Icon from '@/components/Icon';
import Divider from '@/components/Divider';
import Card from '@/components/Card';

import currency from '@/currency';
import axios from 'axios';

export default {
  name: 'Account',

  props: ['id'],

  data() {
    return {
      ok: false,
      account: {},
      currency,

      databaseStatus: this.$game.databaseStatus,
    };
  },

  components: {
    Icon,
    Divider,
    Card,
  },

  methods: {
    rarity(account, cardRarityType) {
      let ans = 0;
      for (let cardID of account.cardIDs || []) {
        ans += this.$db.cards[cardID].cardRarityType == cardRarityType;
      }
      return ans;
    },
    select(account) {
      this.account = account;
      this.dialog = true;
    },
    name(characterID) {
      return (this.$db.gameCharacters[characterID].firstName || '') + (this.$db.gameCharacters[characterID].givenName || '');
    },
    hasCharacter(account, id) {
      for (let cardID of account.cardIDs) {
        if (this.$db.cards[cardID].characterId == id) {
          return true;
        }
      }
      return false;
    },
    hasCard(account, id) {
      for (let cardID of account.cardIDs) {
        if (cardID == id) {
          return true;
        }
      }
      return false;
    },
    load() {
      this.ok = false;
      let cache = this.$game.cache;
      if (cache.accounts[this.id]) {
        this.account = cache.accounts[this.id];
        this.ok = true;
      } else {
        axios.get(`${this.$shop}/account/${this.id}`).catch(e => {
          this.$root.$emit('networkError', e.response?.status);
        }).then(response => {
          this.account = response.data;
          cache.accounts[this.id] = response.data;
          this.ok = true;
        });
      }
    }
  },

  computed: {
    // account() {
    //   return this.$root.accounts.find(account => account.userID == this.id);
    // }
  },

  mounted() {
    this.$game.loadDatabase();
    this.load();
  },

  watch: {
    $route(to) {
      if (to.name == 'Account' && to.fullPath != this.fullPath) {
        this.load();
      }
    }
  }
};
</script>