<template lang="pug">
  div(style="max-width: 768px; margin: auto")
    v-list-item(dense)
      v-list-item-title Select Chatacters
    Divider

    v-tabs(v-model="tab", fixed-tabs)
      v-tab(v-for="unitProfile in $db.unitProfiles", :key="`filter-tab-unit-${unitProfile.unit}`")
        v-img(contain, height=32, width=48, :src="require(`@/assets/unit/logo_${unitProfile.unit}.png`)")

    Divider

    v-tabs-items(v-model="tab")
      v-tab-item(v-for="unitProfile, unit in $db.unitProfiles", :key="`filter-unit-${unit}`")
        v-list.py-0(dense)
          template(v-for="gameCharacter, j in Object.values($db.gameCharacters).filter(gameCharacter => gameCharacter.unit == unitProfile.unit)")
            Divider(inset=64, v-if="j")
            v-menu(:key="`filter-unit-character-${gameCharacter.id}`", offset-x, top, nudge-left=256+12, :close-on-content-click="false", max-width=256)
              template(v-slot:activator="{on, attrs}")
                v-list-item(v-on="on", v-bind="attrs")
                  v-list-item-avatar(size=32)
                    v-img(:src="require(`@/assets/chara/chr_ts_${gameCharacter.id}.png`)")
                  v-list-item-title {{name(gameCharacter.id)}}
                  v-icon(small) mdi-chevron-right

              v-list.overflow-y-auto(dense, width=256, max-height="calc(100vh - 24px)")
                v-list-item(@click="selectCharacter(gameCharacter.id, $game.isRarePrimary.bind($game))") 
                  v-list-item-title All ★4
                  v-icon(small) mdi-check-all
                v-divider
                //- template(v-for="card, k in Object.values($db.cards).filter(card => card.gachaPhrase != '-' && card.characterId == gameCharacter.id)")
                template(v-for="card, k in Object.values($db.cards).filter(card => $game.isRarePrimary(card.id) && card.characterId == gameCharacter.id)")
                  Divider(inset=72, v-if="k")
                  Card(:key="`filter-unit-character-${gameCharacter.id}-card-${card.id}`", :id="card.id", selectable)
                v-divider
                v-list-item(@click="showRarity3 = !showRarity3") 
                  v-list-item-title ★3
                  v-icon(small)
                    template(v-if="showRarity3") mdi-chevron-up
                    template(v-else) mdi-chevron-down
                v-expand-transition
                  div(v-show="showRarity3")
                    v-divider
                    template(v-for="card, k in Object.values($db.cards).filter(card => $game.isRareSecondary(card.id) && card.characterId == gameCharacter.id)")
                      Divider(inset=72, v-if="k")
                      Card(:key="`filter-unit-character-${gameCharacter.id}-card-${card.id}`", :id="card.id", selectable)
          Divider(inset=64)
          v-list-item(@click="selectUnit(unitProfile.unit, $game.isRarePrimary.bind($game))") 
            .mr-4(style="width: 32px")
            v-list-item-content
              v-list-item-title All ★4
            v-icon(small) mdi-check-all


    Divider

    .py-2

    v-list-item(dense)
      v-list-item-title Select in Recent Gachas
    Divider

    v-list.py-0(dense)
      template(v-for="gachaID, i in gachaIDs.slice(-recentGacha).reverse()")
        Divider(inset=102, v-if="i", :key="`filter-gacha-divider-${i}`")
        v-menu(:key="`filter-gacha-menu-${gachaID}`", offset-x, top, nudge-left=256+8, :close-on-content-click="false", max-width=256)
          template(v-slot:activator="{on, attrs}")
            v-list-item(v-on="on", v-bind="attrs", :key="`filter-gacha-item-${gachaID}`")
              .my-2.mr-4(style="height: 32px; width: 70px")
                v-img(contain, :src="`${$assets()}/gacha/${$db.gachas[gachaID].assetbundleName}/logo/logo.webp`")
              v-list-item-content
                v-list-item-title {{$db.gachas[gachaID].name}}
                v-list-item-subtitle {{new Date($db.gachas[gachaID].startAt).toLocaleDateString()}} ~ {{new Date($db.gachas[gachaID].endAt).toLocaleDateString()}}
              v-icon(small) mdi-chevron-right

          v-list(dense, width=256)
            v-list-item
              v-img.my-2(contain, height=64, :src="`${$assets()}/gacha/${$db.gachas[gachaID].assetbundleName}/logo/logo.webp`")
            v-divider
            v-list-item(@click="selectGacha(gachaID)") 
              v-list-item-title All
              v-icon(small) mdi-check-all
            v-divider
            template(v-for="gachaPickup, j in $db.gachas[gachaID].gachaPickups")
              Divider(inset=72, v-if="j")
              Card(:key="`filter-gacha-pickup-${gachaPickup.id}`", :id="gachaPickup.cardId", selectable)
      Divider(inset=102)
      v-list-item(@click="recentGacha += 3")
        .mr-4(style="width: 70px")
        v-list-item-content
          v-list-item-title More..
    Divider

    .py-2

    v-list-item(dense)
      v-list-item-title Check Your Selections
      v-btn(icon, @click="query.cardIds=[]")
        v-icon(small) mdi-backspace
    Divider

    v-list.py-0(dense)
      .pl-4.pr-2.pb-2(v-if="query.cardIds.length")
        v-chip.mt-2.mr-2(v-for="cardID, i in query.cardIds", :key="`filter-chip-card-${cardID}`",
          pill, close, @click:close="query.cardIds.splice(i, 1)")
          v-avatar(left)
            v-img(:src="`${$assets()}/thumbnail/chara/${$db.cards[cardID].assetbundleName}_normal.webp`")
          | {{name($db.cards[cardID].characterId, true)}}
      v-list-item(v-else, dense)
        v-list-item-subtitle None
    //- Scroller.py-2(:key="`filter-check-scroller`", icon=40)
    //-   div.pl-2
    //-   Icon.pl-2(v-for="cardID in filter.cardIds", :id="cardID", size=40)
    //-   div.px-2
    Divider

    .py-2

    v-list-item(dense)
      v-list-item-title # of ★4
    Divider
    v-list.py-0(dense)
      v-list-item
        //- v-range-slider.my-2(v-model="filter.amount", dense, :min="$game.amount[0]", :max="$game.amount[1]", hide-details)
        //-   template(v-slot:prepend)
        //-     v-text-field.ma-0(dense, hide-details, single-line, type="number", style="width: 32px", 
        //-       :value="filter.amount[0]", @change="$set(filter.amount, 0, $event)")
        v-slider.my-2(v-model="query.userCardCountByRarePrimary", dense, :min="$game.amount[0]", :max="$game.amount[1]", hide-details)
          template(v-slot:append)
            v-text-field.ma-0(dense, hide-details, single-line, type="number", style="width: 32px", 
              :value="query.userCardCountByRarePrimary", @change="$set(filter.amount, 0, $event)")      
    Divider

    .py-2

    .px-4
      v-btn(color="primary", block, :to="`?userCardCountByRarePrimary=${query.userCardCountByRarePrimary}${query.cardIds.map(cardId => `&cardId=${cardId}`).join('')}`")
        v-icon mdi-magnify
        span Search

    .py-2

</template>

<script>
import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import Scroller from '@/components/Scroller';

import Card from '@/components/Card';

export default {
  name: 'Selector',

  components: { Card, Divider, Icon, Scroller },

  data() {
    return {
      query: this.$game.query,
      id: {
        input: '',
        dialog: false,
      },
      show: true,
      showRarity3: false,
      tab: 2,
      recentGacha: 3,
    };
  },

  computed: {
    gachaIDs() {
      let cardIds = {};
      let gachaIDs = [];

      for (let gacha of Object.values(this.$db.gachas).sort((a, b) => (a.startAt - b.startAt))) {
        if (gacha.startAt > new Date().valueOf()) {
          continue;
        }

        let flag = false;
        for (let pickup of gacha.gachaPickups) {
          if (!this.$game.isRarePrimary(pickup.cardId)) {
            continue;
          }
          if (!cardIds[pickup.cardId]) {
            cardIds[pickup.cardId] = true;
            flag = true;
          }
        }
        if (flag) {
          gachaIDs.push(gacha.id);
        }
      }

      return gachaIDs;
    },
  },

  methods: {
    name(characterID, short) {
      if (short) {
        return this.$db.gameCharacters[characterID].givenName || this.$db.gameCharacters[characterID].firstName;
      } else {
        return (this.$db.gameCharacters[characterID].firstName || '') + ' ' + (this.$db.gameCharacters[characterID].givenName || '');
      }
    },
    clickID() {
      this.id.dialog = true;
      setTimeout(() => this.$refs.id.focus(), 1);
    },
    selectUnit(unit, func) {
      let cards = Object.values(this.$db.cards).filter(card =>
        func(card.id) &&
        this.$db.gameCharacters[this.$db.cards[card.id].characterId].unit == unit);
      let cardIds = cards.map(card => card.id);
      this.query.cardIds.push(...cardIds.filter(cardID => this.query.cardIds.indexOf(cardID) == -1));
    },
    selectCharacter(characterID, func) {
      let cards = Object.values(this.$db.cards).filter(card =>
        func(card.id) &&
        card.characterId == characterID);
      let cardIds = cards.map(card => card.id);
      this.query.cardIds.push(...cardIds.filter(cardID => this.query.cardIds.indexOf(cardID) == -1));
    },
    selectGacha(gachaID) {
      let cardIds = this.$db.gachas[gachaID].gachaPickups.map(gachaPickup => gachaPickup.cardId);
      this.query.cardIds.push(...cardIds.filter(cardID => this.query.cardIds.indexOf(cardID) == -1));
    },
  },

  watch: {
    'query.cardIds'() {
      this.$nextTick(() => this.show = true);
    }
  },

  mounted() {
    window.onscroll = () => {
      let scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
      let bottomOfWindow = scrollTop + window.innerHeight >= document.documentElement.offsetHeight;
      if (scrollTop && bottomOfWindow) {
        this.$nextTick(this.show = false);
      }
    };
  }
};
</script>
