const currencies = [
  {
    sign: '$',
    name: 'USD',
    rate: 1,
    languages: ['en'],
  },
  {
    sign: '¥',
    name: 'CNY',
    rate: 5,
    languages: ['zh'],
  },
  {
    sign: '¥',
    name: 'JPY',
    rate: 120,
    languages: ['ja'],
  },
];

function getCurrency() {
  for (let language of navigator.languages) {
    for (let currency of currencies) {
      for (let lang of currency.languages) {
        if (language.startsWith(lang)) {
          return currency;
        }
      }
    }
  }
  return currencies[0];
}

export default {
  currencies: currencies,
  currency: getCurrency(),
};
