import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home';
import Search from '@/views/Search';
import Account from '@/views/Account';
import Order from '@/views/Order';
import Recover from '@/views/Recover';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
  },
  {
    path: '/:gameID/search',
    name: 'Search',
    component: Search,
    props: true,
  },
  {
    path: '/:gameID/account/:id',
    name: 'Account',
    component: Account,
    props: true,
  },
  {
    path: '/order',
    name: 'Order',
    component: Order,
    props: true,
  },
  {
    path: '/recover',
    name: 'Recover',
    component: Recover,
    props: true,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    let ans = undefined;
    if (savedPosition) {
      ans = savedPosition;
    } else if (to.path == from.path) {
      ans = false;
    } else {
      ans = { x: 0, y: 0 };
    }

    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(ans);
      }, 0)
    })
  },

  // scrollBehavior(to, from, savedPosition) {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else if (to.path == from.path) {
  //     return false;
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // }
});

export default router;
