<template lang="pug">
  v-app(style="font-family: Avenir")
    v-app-bar(app, color="primary", dark, style="max-width: 768px; margin: 0 auto", elevation=0)
      //- v-btn(icon, href="https://detail.tmall.com/item.htm?id=631688299682", target="_blank")
      //-   v-icon mdi-store

      v-btn(icon, to="/", active-class="no-active")
        v-icon mdi-home

      v-toolbar-title Project Sekai Shop

      v-spacer

      v-menu
        template(v-slot:activator="{on, attrs}")
          v-btn(text, v-on="on", v-bind="attrs")
            | {{currency.currency.sign}}: {{currency.currency.name}}
        v-list(dense)
          v-list-item(v-for="c in currency.currencies", :key="`currency-${c.name}`", @click="currency.currency = c")
            v-list-item-title {{c.sign}}: {{c.name}}

    v-main(style="width: 100%; max-width: 768px; margin: 0 auto")
      v-container.pa-0()

        //- .text-center.py-8(v-if="databaseStatus==0")
        //-   v-progress-circular(color="primary", rotate=-90,
        //-     :indeterminate="!databaseProgress", :value="databaseProgress.loaded / databaseProgress.total * 100")
        //-   .pa-2.caption
        //-     span Loading Database...
        //-     span(v-show="databaseProgress")  {{databaseProgress.loaded}} / {{databaseProgress.total}}

        //- div(v-else)
        //- keep-alive
        router-view

    v-snackbar(v-model="snackbar", :timeout="-1")
      .text-center(v-if="databaseStatus==2")
        v-progress-circular(color="primary", rotate=-90,
          :indeterminate="!databaseProgress", :value="databaseProgress.loaded / databaseProgress.total * 100")
        .pa-2.caption
          span Loading Database...
          span(v-show="databaseProgress")  {{databaseProgress.loaded}} / {{databaseProgress.total}}

      .text-center.mb-2(v-show="databaseStatus==3") Database Updated.
      .text-center.mb-2(v-show="applicationStatus") Application Updated.
      .text-center.mb-2(v-show="networkError!==false") Error {{networkError}}.

      v-row.mx-n2.my-0
        v-col.px-2.py-0
          v-btn.px-0.mt-2(block, color="primary", outlined, @click="snackbar=false") Dismiss
        v-col.px-2.py-0
          v-btn.px-0.mt-2(block, color="primary", outlined, @click="reload()", v-show="refresh")
            v-icon mdi-refresh
            span Refresh
</template>

<script>
// import axios from 'axios';
import currency from '@/currency';
// import '@/database';

export default {
  name: 'App',

  data: () => ({
    ok: false,
    currency: currency,

    refresh: false,
    snackbar: false,
    databaseStatus: 0, // 0: no db; 1: ok, 2: updating, 3: updated
    databaseProgress: 0,
    applicationStatus: false,
    networkError: false,
  }),

  methods: {
    reload() {
      return window.location.reload(true);
    },
    back() {
      return this.$router.go(-1);
    }
  },

  mounted() {
    // this.$root.$on('databaseLoading', (x) => {
    //   this.databaseProgress = x;
    // });
    // this.$root.$on('databaseLoaded', () => {
    //   this.databaseStatus = 1;
    // });
    // this.$root.$on('databaseUpdating', () => {
    //   this.databaseStatus = 2;
    //   this.snackbar = true;
    // });
    // this.$root.$on('databaseUpdated', () => {
    //   this.databaseStatus = 3;
    //   this.snackbar = true;
    //   this.refresh = true;
    // });
    this.$root.$on('applicationUpdated', () => {
      this.applicationStatus = true;
      this.snackbar = true;
      this.refresh = true;
    });
    this.$root.$on('networkError', code => {
      this.networkError = code;
      this.snackbar = true;
      this.refresh = true;
    });
  },
};
</script>

<style>
.v-list-item__action {
  min-width: 0 !important;
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.v-slide-group__prev--disabled {
  display: none !important;
}

.theme--light.v-application {
  background-color: #fafafa !important;
}

.theme--light.v-tabs-items {
  background-color: transparent !important;
}</style>
